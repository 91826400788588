.libretto-video-conference {
    position: relative;
    display: flex;
    align-items: stretch;
    height: 100%;
    background-color: #ffffff;
}

.libretto-video-conference-inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%
}

.libretto-focus-layout-wrapper, .libretto-grid-layout-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%
}

.libretto-grid-layout-wrapper {
    flex-direction: column;
    align-items: center
}

.libretto-focus-layout-wrapper {
    align-items: stretch
}
